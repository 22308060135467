import React, {Fragment} from 'react';
import ImageOne from "../../../Assets/Website/assets/img/blog/blog-5.jpg"
import ImageTwo from "../../../Assets/Website/assets/img/blog/blog-details.jpg"
import ImageThree from "../../../Assets/Website/assets/img/blog/blog-details.jpg"
import ImageFour from "../../../Assets/Website/assets/img/blog/comment-1.jpg"
import ImageFive from "../../../Assets/Website/assets/img/blog/comment-2.jpg"

const MediaDetailContentItem = () => {
  return (
    <Fragment>
      <div className="blog-details-wrapper ml-20">
        <div className="blog-details-top">
          <div className="blog-details-img">
            <img alt="Image_One" src={ImageOne} />
          </div>
          <div className="blog-details-content">
            <div className="blog-meta-2">
              <ul>
                <li>22 April, 2018</li>
                <li><a href="#">4 <i className="fa fa-comments-o" /></a></li>
              </ul>
            </div>
            <h3>New Award Collection Our Shop</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprhendit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qei officia deser mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
              {' '}
            </p>
            <blockquote>
              Lorem ipsum dolor sit amet, consecte adipisicing elit, sed do eiusmod tempor incididunt labo dolor magna aliqua. Ut enim ad minim veniam quis nostrud.
            </blockquote>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehendrit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
          </div>
        </div>
        <div className="dec-img-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div className="dec-img mb-50">
                <img alt="Image_Two" src={ImageTwo} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="dec-img mb-50">
                <img alt="Image_Three" src={ImageThree} />
              </div>
            </div>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehendrit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </div>
        <div className="tag-share">
          <div className="dec-tag">
            <ul>
              <li><a href="#">lifestyle ,</a></li>
              <li><a href="#">interior ,</a></li>
              <li><a href="#">outdoor</a></li>
            </ul>
          </div>
          <div className="blog-share">
            <span>share :</span>
            <div className="share-social">
              <ul>
                <li>
                  <a className="facebook" href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a className="twitter" href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a className="instagram" href="#">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="next-previous-post">
          <a href="#"> <i className="fa fa-angle-left" /> prev post</a>
          <a href="#">next post <i className="fa fa-angle-right" /></a>
        </div>
        <div className="blog-comment-wrapper mt-55">
          <h4 className="blog-dec-title">comments : 02</h4>
          <div className="single-comment-wrapper mt-35">
            <div className="blog-comment-img">
              <img src={ImageFour} alt="Image_Four" />
            </div>
            <div className="blog-comment-content">
              <h4>Anthony Stephens</h4>
              <span>October 14, 2018 </span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua. Ut enim ad minim veniam,
                {' '}
              </p>
              <div className="blog-details-btn">
                <a href=" ">read more</a>
              </div>
            </div>
          </div>
          <div className="single-comment-wrapper mt-50 ml-120">
            <div className="blog-comment-img">
              <img src={ImageFive} alt="Image_Five" />
            </div>
            <div className="blog-comment-content">
              <h4>DX Joxova</h4>
              <span>October 14, 2018 </span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua. Ut enim ad minim veniam,
                {' '}
              </p>
              <div className="blog-details-btn">
                <a href="">read more</a>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-reply-wrapper mt-50">
          <h4 className="blog-dec-title">post a comment</h4>
          <form className="blog-form" action="#">
            <div className="row">
              <div className="col-md-6">
                <div className="leave-form">
                  <input type="text" placeholder="Full Name" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="leave-form">
                  <input type="email" placeholder="Email Address " />
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-leave">
                  <textarea placeholder="Message" defaultValue={''} />
                  <input type="button" defaultValue="SEND MESSAGE" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </Fragment>
  );
};

export default MediaDetailContentItem;
