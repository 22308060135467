import React, { Fragment } from "react";

const ReviewsRatting = (props) => {
	// Rattings...
	const MAX_STARS = 5;
	const yellowStars = Math.min(props.ratting, MAX_STARS);
	const greyStars = MAX_STARS - yellowStars;

	// Render Stars...
	const renderStars = (count, star_color) => {
		const stars = [];
		for (let i = 0; i < count; i++) {
			stars.push(
				<i key={i} className={`fa fa-star-o ${star_color  !== "" ? star_color : ""}`} />
			);
		}
		return stars;
	};
	return (
		<Fragment>
			<div className="pro-details-rating-wrap">
				<div className="pro-details-rating">
					{renderStars(yellowStars, "yellow")}
					{renderStars(greyStars, "")}
				</div>
				<span>{props.ratting} Reviews</span>
			</div>
		</Fragment>
	);
};

export default ReviewsRatting;
