import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';
import * as Data from '../../../Data';

const AwardsAndTrophiesContent = props => {
  console.log(props)
  return (
    <Fragment>
      <div className="shop-bottom-area mt-35">
        {/* Content Area */}
        <div className="tab-content jump">
          <div id="shop-1" className="tab-pane active">
            <div className="row">
              <ReuseableComponents.AwardAndTrophyCard
                data={
                  props.award_and_trophies_category === 'crystal'
                    ? Data.CrystalAwards
                    : props.award_and_trophies_category === 'metal'
                        ? Data.MetalAwards
                        : props.award_and_trophies_category === 'acrylic'
                            ? Data.AcrylicAwards
                            : props.award_and_trophies_category === 'wooden' &&
                                Data.WoodenAwards
                }
                award_and_trophies_category={props.award_and_trophies_category}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AwardsAndTrophiesContent;
