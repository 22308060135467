import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const AboutBannersContent = () => {
	return (
		<Fragment>
			<div className="container">
				<div className="row">
					<ReuseableComponents.AboutBannersContentData />
				</div>
			</div>
		</Fragment>
	);
};

export default AboutBannersContent;
