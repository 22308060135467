import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const Header = () => {
	return (
		<Fragment>
			<footer className="footer-area bg-gray pt-100">
				<ReuseableComponents.Footer />
			</footer>
		</Fragment>
	);
};

export default Header;
