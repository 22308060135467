import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';

const AwardsAndTrophiesTab = () => {
  return (
    <Fragment>
      <div className="product-area pt-95 pb-70">
        <ReuseableComponents.AwardAndTrophyTabContent />
      </div>
    </Fragment>
  );
};

export default AwardsAndTrophiesTab;
