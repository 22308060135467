import React, {Fragment} from 'react';
import * as Wrappers from '../../../Wrappers';

const AwardsAndTrophies = () => {
  // Site Title...
  document.title = 'Media || Crystal Craft';

  return (
    <Fragment>
      {/* Bread Crumb */}
      <Wrappers.BreadCrumb actve_item_title="media" />

      {/* Main Content */}
      <Wrappers.MainContent main_class="Blog-area blog-no-sidebar" />
    </Fragment>
  );
};

export default AwardsAndTrophies;
