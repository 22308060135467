import React, {Fragment} from 'react';
import * as Components from "../../../Components"

const MediaDetailContent = () => {
  return (
    <Fragment>
      <div className="row flex-row-reverse">
        <div className="col-12" >
			<Components.MediaDetailContentItem />
		</div>
      </div>
    </Fragment>
  );
};

export default MediaDetailContent;
