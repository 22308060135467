import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';

const BreadCrumb = props => {
  return (
    <Fragment>
      <div className="breadcrumb-area pt-35 pb-35 bg-gray-3">
        <ReuseableComponents.BreadCrumbContent
          actve_item_title={props.actve_item_title}
        />
      </div>
    </Fragment>
  );
};

export default BreadCrumb;
