import React, { Fragment } from "react";
import * as Components from "../../../Components";

const AboutBanners = () => {
	return (
		<Fragment>
			<div className="banner-area pb-70">
				<Components.AboutBannersContent />
			</div>
		</Fragment>
	);
};

export default AboutBanners;
