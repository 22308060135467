import React, {Fragment} from 'react';
import OwlCarousel from 'react-owl-carousel';
import * as ReuseableComponents from '../../../ReuseableComponents';
import * as Data from '../../../Data';

const RelatedAwardsAndTrophiesContent = props => {
  // Owl Carousel Options...
  const owlCarouselOptions = {
    center: false,
    loop: true,
    nav: false,
    dots: false,
    autoplay: false,
    margin: 30,
    autoplayHoverPause: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <Fragment>
      <OwlCarousel className="related-product-active" {...owlCarouselOptions}>
        <ReuseableComponents.AwardAndTrophyCard
          data={
            props.award_and_trophies_category === 'crystal'
              ? Data.CrystalAwards
              : props.award_and_trophies_category === 'metal'
                  ? Data.MetalAwards
                  : props.award_and_trophies_category === 'acrylic'
                      ? Data.AcrylicAwards
                      : props.award_and_trophies_category === 'wooden' &&
                          Data.WoodenAwards
          }
          related_awards_and_trophies={true}
        />
      </OwlCarousel>
    </Fragment>
  );
};

export default RelatedAwardsAndTrophiesContent;
