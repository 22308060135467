import React, { Fragment } from "react";
import * as Components from "../../../Components";

const HomeSliderContent = (props) => {
	return (
		<Fragment>
			<div className="slider-active nav-style-1">
					<Components.HomeSliderItem data={props.data} />
			</div>
		</Fragment>
	);
};

export default HomeSliderContent;
