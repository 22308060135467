import React, {Fragment} from 'react';
import * as Components from '../../../Components';

const AwardAndTrophyTabContent = () => {
  return (
    <Fragment>
      <div className="container">
        {/* Section Title One */}
        <Components.SectionTitleOne title="Awards!" />

        {/* Award And Trophies Tab */}
        <Components.AwardsAndTrophiesTab />
      </div>
    </Fragment>
  );
};

export default AwardAndTrophyTabContent;
