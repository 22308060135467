import React, {Fragment} from 'react';
import * as Wrappers from '../../../Wrappers';
import * as Hooks from '../../../Hooks';

const AwardsAndTrophies = () => {
  // Site Title...
  document.title = 'Awards And Trophies || Crystal Craft';

  // Params...
  const {award_and_trophy_category_name} = Hooks.useParams ();

  return (
    <Fragment>
      {/* Bread Crumb */}
      <Wrappers.BreadCrumb
        actve_item_title={award_and_trophy_category_name + ' awards'}
      />

      {/* Main Content */}
      <Wrappers.MainContent
        main_class="shop-area"
        award_and_trophies_category={award_and_trophy_category_name}
      />
    </Fragment>
  );
};

export default AwardsAndTrophies;
