import React, {Fragment} from 'react';
import * as Hooks from '../../../Hooks';

const BreadCrumbContentItem = props => {
  return (
    <Fragment>
      <div className="breadcrumb-content text-center">
        <ul>
          <li>
            <Hooks.Link to="/">Home</Hooks.Link>
          </li>
          <li className="active">{props.actve_item_title}</li>
        </ul>
      </div>
    </Fragment>
  );
};

export default BreadCrumbContentItem;
