import React, { Fragment } from "react";
import * as Hooks from "../../../Hooks";
import ImageOne from "../../../Assets/Website/assets/img/banner/velvet_box.jpeg";
import ImageTwo from "../../../Assets/Website/assets/img/banner/leather_box.jpg";

const BannerAreaContentItem = () => {
	return (
		<Fragment>
			{/* Item 1 */}
			<div className="col-lg-6 col-md-6">
				<div className="single-banner-2 mb-30 home_banner_image">
					<Hooks.Link to="">
						<img
							src={ImageOne}
							alt="Velvet_Box_Images"
							className="rounded"
						/>
					</Hooks.Link>
					<div className="banner-content-2 jewellery-banner">
						<h3>Velvet Boxes</h3>
						<h4>
							Starting at <span>د.إ 0.00</span>
						</h4>
						<Hooks.Link to="">
							<i className="fa fa-long-arrow-right" />
						</Hooks.Link>
					</div>
				</div>
			</div>

			{/* Item 2 */}
			<div className="col-lg-6 col-md-6">
				<div className="single-banner-2 mb-30 home_banner_image">
					<Hooks.Link to="">
						<img
							src={ImageTwo}
							alt="Leather_Box_Image"
							className="rounded"
						/>
					</Hooks.Link>
					<div className="banner-content-2 jewellery-banner">
						<h3>Leather Boxes</h3>
						<h4>
							Starting at <span>د.إ 0.00</span>
						</h4>
						<Hooks.Link to="">
							<i className="fa fa-long-arrow-right" />
						</Hooks.Link>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default BannerAreaContentItem;
