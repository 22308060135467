import React, { Fragment } from "react";
import * as Wrappers from "../../../Wrappers";

const ContactUs = () => {
	// Site Title...
	document.title = "Contact Us || Crystal Craft";

	return (
		<Fragment>
			{/* Bread Crumb */}
			<Wrappers.BreadCrumb actve_item_title="contact us" />

			{/* Main Content */}
			<Wrappers.MainContent main_class="contact-area" />
		</Fragment>
	);
};

export default ContactUs;
