import React, { Fragment } from "react";
import * as ReuseableComponents from "../../index";
import * as Hooks from "../../../Hooks";

const HeaderMobileMenu = () => {
	// States...
	const [icon, setIcon] = Hooks.useState(true);

	// On Trigger Mobile Menu...
	const onTriggerMobileMenu = () => {
		if (icon === true) {
			setIcon(false);
			document.querySelector(".mobile-menu-area").style.display = "block";
		} else {
			setIcon(true);
			document.querySelector(".mobile-menu-area").style.display = "none";
		}
	};

	return (
		<Fragment>
			<div className="d-xl-none d-lg-block">
				{icon === true ? (
					<ReuseableComponents.ReactIcons.MENU
						className="mobile_menu_icon"
						onClick={() => onTriggerMobileMenu()}
					/>
				) : (
					<ReuseableComponents.ReactIcons.CLOSE
						className="mobile_menu_icon"
						onClick={() => onTriggerMobileMenu()}
					/>
				)}
			</div>
		</Fragment>
	);
};

export default HeaderMobileMenu;
