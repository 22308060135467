import React, { Fragment } from "react";
import * as Components from "../../../Components";

const AwardsAndTrophies = (props) => {
	return (
		<Fragment>
			<div className="row flex-row-reverse">
				<div className="col-lg-12">
					<Components.AwardsAndTrophiesContent award_and_trophies_category={props.award_and_trophies_category} />
				</div>
			</div>
		</Fragment>
	);
};

export default AwardsAndTrophies;
