import React, { Fragment } from "react";
import "../../Styles/Website";
import * as Hooks from "../../Hooks";
import * as Wrappers from "../../Wrappers";

const Website = () => {
	return (
		<Fragment>
			{/* Header */}
			<Wrappers.Header />

			{/* Main */}
			<main>
				<Hooks.Outlet />
			</main>

			{/* Footer */}
			<Wrappers.Footer />
		</Fragment>
	);
};

export default Website;
