import React, { Fragment } from "react";

const FooterBottom = () => {
	return (
		<Fragment>
			<div className="col-12 text-center py-3">
				<p className="mb-3">
					Copyright &copy; 2023{" "}
					<a
						href="https://xpertsdemo.xpertsgroup.net/"
						target="_blank"
						rel="noopener noreferrer"
						className="footer_website_link">
						Xpert Spot Systems.
					</a>{" "}
					All Rights Reserved
				</p>
			</div>
		</Fragment>
	);
};

export default FooterBottom;
