import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';

const Media = () => {
  return (
    <Fragment>
      <div className="blog-area pb-55">
        <ReuseableComponents.MediaContent />
      </div>
    </Fragment>
  );
};

export default Media;
