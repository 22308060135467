import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Data from "../../../Data";
import { Button } from "react-bootstrap";

const ContactForm = () => {
	return (
		<Fragment>
			<div className="col-lg-7 col-md-12">
				<div className="contact-form">
					<div className="contact-title mb-30">
						<h2 className="mb-1">Feel Free to Contact Us</h2>
						<p>
							Please, Send your inquiry or feedback & we will
							respond as soon as possible
						</p>

						{/* Form Fields */}
						<form
							className="contact-form-style"
							id="contact-form">
							<div className="row">
								{/* Full Name */}
								<div className="col-lg-6">
									<ReuseableComponents.TextInput
										type="text"
										placeholder="Enter Full Name"
										name="full_name"
									/>
								</div>

								{/* Email Address */}
								<div className="col-lg-6">
									<ReuseableComponents.TextInput
										type="text"
										placeholder="Enter Email Address"
										name="email_address"
									/>
								</div>
							</div>

							<div className="row">
								{/* Company Name */}
								<div className="col-lg-6">
									<ReuseableComponents.TextInput
										type="text"
										placeholder="Enter Company Name"
										name="company_name"
									/>
								</div>

								{/* City */}
								<div className="col-lg-6">
									<ReuseableComponents.TextInput
										type="text"
										placeholder="Enter City"
										name="city"
									/>
								</div>
							</div>

							<div className="row">
								{/* Country */}
								<div className="col-lg-6">
									<ReuseableComponents.TextInput
										type="text"
										placeholder="Enter Country"
										name="country"
									/>
								</div>

								{/* City */}
								<div className="col-lg-6">
									<ReuseableComponents.SelectField
										default_option_text="Select Inquiry Type"
										name="inquiry_type"
										options={Data.InquiryTypes}
									/>
								</div>
							</div>

							<div className="row">
								{/* Message */}
								<div className="col-12">
									<ReuseableComponents.TextArea
										type="text"
										placeholder="Enter Message"
										name="message"
									/>
								</div>
							</div>

							{/* Submit Button */}
							<Button
								type="button"
								className="rounded-0">
								Submit
							</Button>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ContactForm;
