import { MdLocationOn } from "react-icons/md";
import {
	AiFillStar,
	AiOutlinePlus,
	AiOutlineClose,
	AiOutlineCloseCircle,
} from "react-icons/ai";
import {
	FaFacebookF,
	FaTwitter,
	FaWhatsapp,
	FaGooglePlusG,
	FaInstagram,
	FaPinterestP,
	FaShareAlt,
	FaChild,
	FaUsers,
	FaBed,
} from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { TfiYoutube } from "react-icons/tfi";
import {
	BiSolidUserCircle,
	BiLogInCircle,
	BiCheck,
	BiMinus,
} from "react-icons/bi";
import {
	BsFillEnvelopeFill,
	BsFillSunFill,
	BsFillMoonFill,
	BsCalendarWeekFill,
	BsCheck2Square,
} from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { IoIosMan } from "react-icons/io";

const ReactIcons = {
	MENU: HiMenu,
	CLOSE: AiOutlineClose,
	OUTLINE_CLOSE: AiOutlineCloseCircle,
	PLUS: AiOutlinePlus,
	MINUS: BiMinus,

	LOCATION_ON: MdLocationOn,
	FILL_STAR: AiFillStar,
	FACE_BOOK_F: FaFacebookF,
	TWITTER: FaTwitter,
	WHATSAPP: FaWhatsapp,
	GOOGLE_PLUS_G: FaGooglePlusG,
	INSTAGRAM: FaInstagram,
	YOUTUBE: TfiYoutube,
	SOLID_USER_CIRCLE: BiSolidUserCircle,
	LOG_IN_CIRCLE: BiLogInCircle,
	PINTEREST_P: FaPinterestP,
	ENVELOPE_FILL: BsFillEnvelopeFill,
	LINKEDIN_OPTION: GrLinkedinOption,
	SHARE_ALT: FaShareAlt,
	PERSON_MAN: IoIosMan,
	CHILD: FaChild,
	CHECK: BiCheck,
	SUN_FILL: BsFillSunFill,
	MOON_FILL: BsFillMoonFill,
	CALENDAR_FILL: BsCalendarWeekFill,
	USERS: FaUsers,
	CHECK_SQUARE: BsCheck2Square,
	BED: FaBed,
};

export default ReactIcons;
