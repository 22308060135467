import React, { Fragment } from "react";
import * as Components from "../../../Components";

const TestimonialAreaContent = () => {
	return (
		<Fragment>
			<div className="container">
				<div className="row">
					<div className="col-lg-10 ml-auto mr-auto">
						<Components.TestimonialAreaContentItem />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TestimonialAreaContent;
