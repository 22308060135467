import {createBrowserRouter} from 'react-router-dom';
import * as Layouts from '../Layouts';
import * as Pages from '../Pages';

const Routes = [
  {
    path: '/',
    element: <Layouts.Website />,
    errorElement: <h1>error!</h1>,
    children: [
      // Home...
      {
        path: '/',
        element: <Pages.Home />,
      },

      // About Us...
      {
        path: '/about_us',
        element: <Pages.AboutUs />,
      },

      // AwardsAndTrophies...
      // {
      //   path: '/awards_and_trophies',
      //   element: <Pages.AwardsAndTrophies />,
      // },
      // {
      //   path: '/awards_and_trophies/details',
      //   element: <Pages.AwardsAndTrophiesDetails />,
      // },

      //  Awards And Trophies...
      {
        path: '/awards_and_trophies/:award_and_trophy_category_name',
        element: <Pages.AwardsAndTrophies />,
      },

      {
        path: '/awards_and_trophies/:award_and_trophy_category_name/:award_and_trophy_category_id',
        element: <Pages.AwardsAndTrophiesDetails />,
      },

      // Media...
      {
        path: '/media',
        element: <Pages.Media />,
      },
      {
        path: '/media/:media_id',
        element: <Pages.MediaDetails />,
      },

      // Contact Us...
      {
        path: '/contact_us',
        element: <Pages.ContactUs />,
      },
    ],
  },
];

const Route = createBrowserRouter (Routes);

export default Route;
