import React, {Fragment} from 'react';
import * as Components from '../../../Components';

const NewsAndEventsContent = () => {
  return (
    <Fragment>
      <div className="container">
        {/* Section Title One */}
        <Components.SectionTitleOne title="Media!" />

        {/* News And Events Content Item */}
        <div className="row">
          <Components.MediaContentItem />
        </div>
      </div>
    </Fragment>
  );
};

export default NewsAndEventsContent;
