import React, { Fragment } from "react";
import * as Hooks from "../../../Hooks";

const HeaderLogo = (props) => {
	return (
		<Fragment>
			<div className="col-xl-2 col-lg-2 col-md-6 col-4">
				<div className="logo">
					<Hooks.Link to="/">
						{props.image && (
							<img
								src={props.image}
								alt="Logo"
								// height={200}
								// width={200}
							/>
						)}
					</Hooks.Link>
				</div>
			</div>
		</Fragment>
	);
};

export default HeaderLogo;
