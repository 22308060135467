import React, {Fragment} from 'react';
import * as Hooks from '../../../Hooks';
import * as Wrappers from '../../../Wrappers';

const AwardAndTrophyCard = props => {
  // States...
  const [modal, setModal] = Hooks.useState (false);
  const [award_and_trophy_data, setAwardAndTrophyData] = Hooks.useState ([]);

  // Params...
  const {award_and_trophy_category_name} = Hooks.useParams();
  // console.log(award_and_trophy_category_name)

  // On View Award And Trophy Detail...
  const onViewAwardAndTrophyDetail = award_and_trophy_item_data => {
    setModal (true);
    setAwardAndTrophyData (award_and_trophy_item_data);
  };

  return (
    <Fragment>
      {props.data && props.data.length >= 1 && props.visible_items
        ? props.data.slice (0, props.visible_items).map ((item, index) => (
            <div className="col-xl-3 col-md-6 col-lg-4 col-sm-6" key={index}>
              <div className="product-wrap-2 mb-25 product_card">
                <div className="product-img">
                  <Hooks.Link
                    to={`/awards_and_trophies/${props.award_and_trophy_category_name}/${item.id}`}
                    className="cursor_pointer"
                  >
                    {/* Image */}
                    <img
                      className="default-img"
                      src={require (`../../../Assets/Website/assets/img/${item.thumbnail_image}`)}
                      alt={`Product_${index + 1}_Image`}
                    />

                    {/* Hover Image */}
                    <img
                      className="hover-img"
                      src={require (`../../../Assets/Website/assets/img/${item.hover_image}`)}
                      alt={`Product_${index + 1}_Hover_Image`}
                    />
                  </Hooks.Link>

                  {/* Discount */}
                  <span className="pink">
                    {item.discount > 0 ? '-' + item.discount + '%' : ''}
                  </span>

                  {/*Actions  */}
                  <div className="product-action-2">
                    {/* View */}
                    <span
                      title="Quick View"
                      className="quick_view"
                      onClick={() => onViewAwardAndTrophyDetail (item)}
                    >
                      <i className="fa fa-eye" />
                    </span>
                  </div>
                </div>

                <div className="product-content-2">
                  <div className="title-price-wrap-2">
                    {/* Product Name */}
                    <h3>
                      <Hooks.Link
                        to={`/awards_and_trophies/${props.award_and_trophy_category_name}/${item.id}`}
                      >
                        {item.name}
                      </Hooks.Link>
                    </h3>

                    {/* Price */}
                    <div className="price-2">
                      {item.discount > 0
                        ? <Fragment>
                            {/* Discounted Price */}
                            {item.discount > 0 &&
                              <span>
                                {item.currency_symbol +
                                  ' ' +
                                  (item.price -
                                    item.discount / 100 * item.price).toFixed (
                                    2
                                  )}
                              </span>}

                            {/* Original Price */}
                            {item.discount > 0 &&
                              <span className="old">
                                {item.currency_symbol +
                                  ' ' +
                                  item.price.toFixed (2)}
                              </span>}
                          </Fragment>
                        : <Fragment>
                            {/* Original Price */}
                            <span>
                              {item.currency_symbol +
                                ' ' +
                                item.price.toFixed (2)}
                            </span>
                          </Fragment>}
                    </div>
                  </div>

                  {/* Wish List */}
                  <div className="pro-wishlist-2">
                    <Hooks.Link to="" title="Wishlist">
                      <i className="fa fa-heart-o" />
                    </Hooks.Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        : props.related_awards_and_trophies === true
            ? props.data.map ((item, index) => (
                <div className="product-wrap-2 mb-25 product_card" key={index}>
                  <div className="product-img">
                    <Hooks.Link
                      to={`/awards_and_trophies/${award_and_trophy_category_name}/${item.id}`}
                      className="cursor_pointer"
                    >
                      {/* Image */}
                      <img
                        className="default-img"
                        src={require (`../../../Assets/Website/assets/img/${item.thumbnail_image}`)}
                        alt={`Product_${index + 1}_Image`}
                      />

                      {/* Hover Image */}
                      <img
                        className="hover-img"
                        src={require (`../../../Assets/Website/assets/img/${item.hover_image}`)}
                        alt={`Product_${index + 1}_Hover_Image`}
                      />
                    </Hooks.Link>

                    {/* Discount */}
                    <span className="pink">
                      {item.discount > 0 ? '-' + item.discount + '%' : ''}
                    </span>

                    {/*Actions  */}
                    <div className="product-action-2">
                      {/* View */}
                      <span
                        title="Quick View"
                        className="quick_view"
                        onClick={() => onViewAwardAndTrophyDetail (item)}
                      >
                        <i className="fa fa-eye" />
                      </span>
                    </div>
                  </div>

                  <div className="product-content-2">
                    <div className="title-price-wrap-2">
                      {/* Product Name */}
                      <h3>
                        <Hooks.Link
                          to={`/awards_and_trophies/${award_and_trophy_category_name}/${item.id}`}
                        >
                          {item.name}
                        </Hooks.Link>
                      </h3>

                      {/* Price */}
                      <div className="price-2">
                        {item.discount > 0
                          ? <Fragment>
                              {/* Discounted Price */}
                              {item.discount > 0 &&
                                <span>
                                  {item.currency_symbol +
                                    ' ' +
                                    (item.price -
                                      item.discount /
                                        100 *
                                        item.price).toFixed (2)}
                                </span>}

                              {/* Original Price */}
                              {item.discount > 0 &&
                                <span className="old">
                                  {item.currency_symbol +
                                    ' ' +
                                    item.price.toFixed (2)}
                                </span>}
                            </Fragment>
                          : <Fragment>
                              {/* Original Price */}
                              <span>
                                {item.currency_symbol +
                                  ' ' +
                                  item.price.toFixed (2)}
                              </span>
                            </Fragment>}
                      </div>
                    </div>

                    {/* Wish List */}
                    <div className="pro-wishlist-2">
                      <Hooks.Link to="" title="Wishlist">
                        <i className="fa fa-heart-o" />
                      </Hooks.Link>
                    </div>
                  </div>
                </div>
              ))
            : props.data.map ((item, index) => (
                <div
                  className="col-xl-3 col-md-6 col-lg-4 col-sm-6"
                  key={index}
                >
                  <div className="product-wrap-2 mb-25 product_card">
                    <div className="product-img">
                      <Hooks.Link
                        to={`/awards_and_trophies/${award_and_trophy_category_name}/${item.id}`}
                        className="cursor_pointer"
                      >
                        {/* Image */}
                        <img
                          className="default-img"
                          src={require (`../../../Assets/Website/assets/img/${item.thumbnail_image}`)}
                          alt={`Product_${index + 1}_Image`}
                        />

                        {/* Hover Image */}
                        <img
                          className="hover-img"
                          src={require (`../../../Assets/Website/assets/img/${item.hover_image}`)}
                          alt={`Product_${index + 1}_Hover_Image`}
                        />
                      </Hooks.Link>

                      {/* Discount */}
                      <span className="pink">
                        {item.discount > 0 ? '-' + item.discount + '%' : ''}
                      </span>

                      {/*Actions  */}
                      <div className="product-action-2">
                        {/* View */}
                        <span
                          title="Quick View"
                          className="quick_view"
                          onClick={() => onViewAwardAndTrophyDetail (item)}
                        >
                          <i className="fa fa-eye" />
                        </span>
                      </div>
                    </div>

                    <div className="product-content-2">
                      <div className="title-price-wrap-2">
                        {/* Product Name */}
                        <h3>
                          <Hooks.Link
                           to={`/awards_and_trophies/${award_and_trophy_category_name}/${item.id}`}
                          >
                            {item.name}
                          </Hooks.Link>
                        </h3>

                        {/* Price */}
                        <div className="price-2">
                          {item.discount > 0
                            ? <Fragment>
                                {/* Discounted Price */}
                                {item.discount > 0 &&
                                  <span>
                                    {item.currency_symbol +
                                      ' ' +
                                      (item.price -
                                        item.discount /
                                          100 *
                                          item.price).toFixed (2)}
                                  </span>}

                                {/* Original Price */}
                                {item.discount > 0 &&
                                  <span className="old">
                                    {item.currency_symbol +
                                      ' ' +
                                      item.price.toFixed (2)}
                                  </span>}
                              </Fragment>
                            : <Fragment>
                                {/* Original Price */}
                                <span>
                                  {item.currency_symbol +
                                    ' ' +
                                    item.price.toFixed (2)}
                                </span>
                              </Fragment>}
                        </div>
                      </div>

                      {/* Wish List */}
                      <div className="pro-wishlist-2">
                        <Hooks.Link to="" title="Wishlist">
                          <i className="fa fa-heart-o" />
                        </Hooks.Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

      {/* Modal */}
      {modal === true &&
        <Wrappers.AwardAndTrophhyModal
          open_modal={() => setModal (true)}
          close_modal={() => setModal (false)}
          header_title="View Product Detail"
          award_and_trophy_data={award_and_trophy_data}
        />}
    </Fragment>
  );
};

export default AwardAndTrophyCard;
