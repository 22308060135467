import React, { Fragment } from "react";
import * as Components from "../../../Components";

const Footer = () => {
	return (
		<Fragment>
			<div className="container">
				{/* Footer Top */}
				<div className="row">
					<Components.FooterTop />
				</div>

				<hr />

				{/* Footer Bottom */}
				<div className="row">
					<Components.FooterBottom />
				</div>
			</div>
		</Fragment>
	);
};

export default Footer;
