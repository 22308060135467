import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const SupportArea = () => {
	return (
		<Fragment>
			<div className="support-area pb-70">
				<ReuseableComponents.SupportAreaContent />
			</div>
		</Fragment>
	);
};

export default SupportArea;
