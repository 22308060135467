import React, { Fragment } from "react";
import * as Hooks from "../../../Hooks";

const FooterLogo = (props) => {
	return (
		<Fragment>
			<div className="footer-logo">
				<Hooks.Link to="/">
					{props.image && (
						<img
							src={props.image}
							alt="Logo_Image"
						/>
					)}
				</Hooks.Link>
			</div>
		</Fragment>
	);
};

export default FooterLogo;
