import React, {Fragment} from 'react';
import * as Wrappers from '../../../Wrappers';
import * as Hooks from '../../../Hooks';

const MainContentData = props => {
  // Location...
  const location = Hooks.useLocation ();

  // Params...
  const {award_and_trophy_category_id, media_id} = Hooks.useParams();
  return (
    <Fragment>
      <div className="container">
        {location.pathname.split ('/')[1] === 'awards_and_trophies' &&
          location.pathname.split ('/')[2] ===
            props.award_and_trophies_category &&
          props.award_and_trophies_category &&
          location.pathname.split ('/')[3] === undefined
          ? <Wrappers.AwardsAndTrophies
              award_and_trophies_category={props.award_and_trophies_category}
              data={props.data}
            />
          : location.pathname.split ('/')[1] === 'awards_and_trophies' &&
              location.pathname.split ('/')[2] ===
                props.award_and_trophies_category &&
              location.pathname.split ('/')[3] === award_and_trophy_category_id
              ? <Wrappers.AwardsAndTrophiesDetails data={props.data} />
              : location.pathname.split ('/')[1] === 'media' &&
              location.pathname.split ('/')[2] === undefined
                  ? <Wrappers.MediaContent />
                  : location.pathname.split ('/')[1] === 'media' &&
                  location.pathname.split ('/')[2] === media_id &&
                  location.pathname.split ('/')[3] === undefined
                      ? <Wrappers.MediaDetailContent />
                      : location.pathname.split ('/')[1] === 'contact_us'
                          ? <Wrappers.ContactUs />
                          : ''}
      </div>
    </Fragment>
  );
};

export default MainContentData;
