import React, { Fragment } from "react";
import * as Components from "../../../Components";
import * as Wrappers from "../../../Wrappers";

const AboutUs = () => {
	return (
		<Fragment>
			{/* Welcome Area */}
			<Components.WelcomeArea />

			{/* About Banners */}
			<Wrappers.AboutBanners />

			{/* About Strategy */}
			<Components.AboutStrategy />

			{/* Brand Area */}
			<Wrappers.BrandArea />
		</Fragment>
	);
};

export default AboutUs;
