import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Hooks from "../../../Hooks";

const Header = () => {
	// States...
	const [window_scroll, setWindowScroll] = Hooks.useState(false);

	// UseEffect...
	Hooks.useEffect(() => {
		window.addEventListener("scroll", () => {
			setWindowScroll(window.scrollY > 50);
		});
	}, []);
	return (
		<Fragment>
			<header
				className={
					window_scroll
						? "header-area header-padding-2 sticky-bar header-res-padding clearfix stick"
						: "header-area header-padding-2 sticky-bar header-res-padding clearfix"
				}>
				<ReuseableComponents.Header />
			</header>
		</Fragment>
	);
};

export default Header;
