import React, {Fragment} from 'react';
import * as Components from '../../../Components';

const BreadCrumbContent = props => {
  return (
    <Fragment>
      <div className="container">
        <Components.BreadCrumbContentItem
          actve_item_title={props.actve_item_title}
        />
      </div>
    </Fragment>
  );
};

export default BreadCrumbContent;
