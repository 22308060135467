import React, { Fragment } from "react";
import * as Components from "../../../Components";

const BrandAreaContent = () => {
	return (
		<Fragment>
			<div className="container">
				<Components.BrandAreaContentItem />
			</div>
		</Fragment>
	);
};

export default BrandAreaContent;
