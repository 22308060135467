import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';

const AwardAndTrophyDetailContent = props => {
  return (
    <Fragment>
      {/* Image */}
      <div className="col-lg-6 col-md-6">
        <div className="product-details dec-img-wrap">
          <img
            src={require (`../../../Assets/Website/assets/img/${props.data.thumbnail_image}`)}
            alt={`${props.data.name}_Image`}
            className="img-thumbnail"
          />

          {/* Discount */}
          {props.data.discount > 0 && <span>-{props.data.discount}%</span>}
        </div>
      </div>

      {/* Information */}
      <div className="col-lg-6 col-md-6">
        <div className="product-details-content ml-70">
          {/* Name */}
          <h2>{props.data.name}</h2>

          {/* Price */}
          <div className="product-details-price">
            {props.data.discount > 0
              ? <Fragment>
                  {/* Discounted Price */}
                  {props.data.discount > 0 &&
                    <span>
                      {props.data.currency_symbol +
                        ' ' +
                        (props.data.price -
                          props.data.discount /
                            100 *
                            props.data.price).toFixed (2)}
                    </span>}

                  {/* Original Price */}
                  {props.data.discount > 0 &&
                    <span className="old">
                      {props.data.currency_symbol +
                        ' ' +
                        props.data.price.toFixed (2)}
                    </span>}
                </Fragment>
              : <Fragment>
                  {/* Original Price */}
                  <span>
                    {props.data.currency_symbol +
                      ' ' +
                      props.data.price.toFixed (2)}
                  </span>
                </Fragment>}
          </div>

          {/* Reviews Ratting */}
          <ReuseableComponents.ReviewsRatting ratting={props.data.reviews} />

          {/* Description */}
          <p className="text_justify">
            {props.data.short_description}
          </p>

          {/* Offers */}
          {props.data.offers &&
            props.data.offers.length >= 1 &&
            props.data.offers.map ((offer_item, offer_index) => (
              <Fragment key={offer_index}>
                {/* Offer Items */}
                <div className="pro-details-list">
                  <p className="text-muted">
                    <i>{offer_item.heading}</i>
                  </p>
                  <ul className="pl-3">
                    {offer_item.items &&
                      offer_item.items.length >= 1 &&
                      offer_item.items.map (
                        (offer_item_data, offer_item_index) => (
                          <Fragment key={offer_item_index}>
                            <li className="text_justify">
                              {offer_item_data.title &&
                                <b className="mr-2 text-dark">
                                  <i>
                                    {offer_item_data.title}
                                    :
                                  </i>
                                </b>}
                              {offer_item_data.text}
                            </li>
                          </Fragment>
                        )
                      )}
                  </ul>
                </div>
              </Fragment>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default AwardAndTrophyDetailContent;
