import React, { Fragment } from "react";
import * as Wrappers from "../../../Wrappers";

const AboutUs = () => {
	// Site Title...
	document.title = "About Us || Crystal Craft";

	return (
		<Fragment>
			{/* Bread Crumb */}
			<Wrappers.BreadCrumb actve_item_title="about us" />

			{/* Main Content */}
			<Wrappers.AboutUs />
		</Fragment>
	);
};

export default AboutUs;
