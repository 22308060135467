import React, { Fragment } from "react";
import * as Hooks from "../../../Hooks";

const ContactInfo = () => {
	return (
		<Fragment>
			<div className="col-lg-5 col-md-12 mt-3 mt-lg-0">
				<div className="contact-info-wrap">
					{/* Contact No */}
					<div className="single-contact-info">
						<div className="contact-icon">
							<i className="fa fa-phone"></i>
						</div>
						<div className="contact-info-dec">
							<p>
								<a
									href="tel:(+971) 50 156 7293"
									className="ml-2">
									(+971) 50 156 7293
								</a>
							</p>
						</div>
					</div>

					{/* Email Address */}
					<div className="single-contact-info">
						<div className="contact-icon">
							<i className="fa fa-globe"></i>
						</div>
						<div className="contact-info-dec">
							<p>
								<a href="mailto:hello@crystalcraft.ae">
									hello@crystalcraft.ae
								</a>
							</p>
						</div>
					</div>

					{/* Address */}
					<div className="single-contact-info">
						<div className="contact-icon">
							<i className="fa fa-map-marker"></i>
						</div>
						<div className="contact-info-dec">
							<p>Al , Qusais, Dubai</p>
						</div>
					</div>

					{/* Social Icons */}
					<div className="contact-social text-center">
						<h3>Follow Us</h3>
						<ul>
							<li>
								<Hooks.Link to="">
									<i className="fa fa-facebook"></i>
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">
									<i className="fa fa-pinterest-p"></i>
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">
									<i className="fa fa-tumblr"></i>
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">
									<i className="fa fa-vimeo"></i>
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">
									<i className="fa fa-twitter"></i>
								</Hooks.Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ContactInfo;
