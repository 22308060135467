import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const HeaderIcons = () => {
	return (
		<Fragment>
			<div className="col-xl-2 col-lg-2 col-md-6 col-8">
				<div className="header-right-wrap justify-content-lg-center">
					{/* Header Search */}
					{/* <ReuseableComponents.HeaderSearch /> */}

					{/* Header Mobile Menu */}
					<ReuseableComponents.HeaderMobileMenu />
				</div>
			</div>
		</Fragment>
	);
};

export default HeaderIcons;
