import React, {Fragment} from 'react';
import * as Hooks from '../../../Hooks';

const HeaderMenu = () => {
  // Location...
  const location = Hooks.useLocation ();

  // Params...
  const {award_and_trophy_category_name} = Hooks.useParams ();

  return (
    <Fragment>
      <div className="col-xl-8 col-lg-8 d-none d-xl-block">
        <div className="main-menu">
          <nav>
            <ul>
              {/* Home */}
              <li>
                <Hooks.Link
                  to=""
                  className={`${location.pathname.split ('/')[1] === '' ? 'active_link' : ''}`}
                >
                  Home
                </Hooks.Link>
              </li>

              {/* About Us */}
              <li>
                <Hooks.Link
                  to="about_us"
                  className={`${location.pathname.split ('/')[1] === 'about_us' ? 'active_link' : 'about_us'}`}
                >
                  About Us
                </Hooks.Link>
              </li>

              {/* Awards & Trophies */}
              <li>
                <Hooks.Link
                  to=""
                  className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' ? 'active_link' : ''}`}
                >
                  Awards & Trophies
                  <i className="fa fa-angle-down" />
                </Hooks.Link>
                <ul className="submenu">
                  {/* Crystal Awards */}
                  <li>
                    <Hooks.Link
                      to="awards_and_trophies/crystal"
                      className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'crystal' ? 'active_link' : ''}`}
                    >
                      Crystal Awards
                    </Hooks.Link>
                  </li>

                  {/* Metal Awards */}
                  <li>
                    <Hooks.Link
                      to="awards_and_trophies/metal"
                      className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'metal' ? 'active_link' : ''}`}
                    >
                      Metal Awards
                    </Hooks.Link>
                  </li>

                  {/* Acrylic Awards */}
                  <li>
                    <Hooks.Link
                      to="awards_and_trophies/acrylic"
                      className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'acrylic' ? 'active_link' : ''}`}
                    >
                      Acrylic Awards
                    </Hooks.Link>
                  </li>

                  {/* Wooden Awards */}
                  <li>
                    <Hooks.Link
                      to="awards_and_trophies/wooden"
                      className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'wooden' ? 'active_link' : ''}`}
                    >
                      Wooden Awards
                    </Hooks.Link>
                  </li>

                  {/* Marble Awards */}
                  <li>
                    <Hooks.Link to="#">Marble Awards</Hooks.Link>
                  </li>

                  {/* Boxes */}
                  <li>
                    <Hooks.Link to="#">Boxes</Hooks.Link>
                  </li>
                </ul>
              </li>

              {/* Media */}
              <li>
                <Hooks.Link
                  to="media"
                  className={`${location.pathname.split ('/')[1] === 'media' ? 'active_link' : ''}`}
                >
                  Media
                </Hooks.Link>
              </li>

              {/* Contact Us */}
              <li>
                <Hooks.Link
                  to="contact_us"
                  className={`${location.pathname.split ('/')[1] === 'contact_us' ? 'active_link' : ''}`}
                >
                  Contact Us
                </Hooks.Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderMenu;
