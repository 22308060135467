import React, {Fragment} from 'react';
import Image from '../../../Assets/Website/assets/img/blog/blog-9.jpg';
import * as Hooks from '../../../Hooks';

const MediaContentItem = () => {
  return (
    <Fragment>
      {[1, 2, 3, 4, 5, 6].map ((_, index) => (
        <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
          <div className="blog-wrap-2 mb-30">
            <div className="blog-img-2">
              <Hooks.Link to={`/media/${index + 1}`}>
                <img src={Image} alt="Image_One" />
              </Hooks.Link>
            </div>
            <div className="blog-content-2">
              <div className="blog-meta-2">
                <ul>
                  <li>22 April, 2018</li>
                  <li><span>4 <i className="fa fa-comments-o" /></span></li>
                </ul>
              </div>
              <h4>
                <Hooks.Link to={`/media/${index + 1}`}>
                  New award collection our shop
                </Hooks.Link>
              </h4>
              <p>
                You excel in crafting 2D crystal awards that feature
                precise laser engravings, allowing for the inclusion of
                intricate designs, text, and artwork. These awards
                showcase a flat representation with varying depths,
                creating a sophisticated and elegant look.
              </p>
              <div className="blog-share-comment">
                <div className="blog-btn-2">
                  <Hooks.Link to={`/media/${index + 1}`}>
                    read more
                  </Hooks.Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default MediaContentItem;
