import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';

const MainContent = props => {
  return (
    <Fragment>
      <div className={`pt-100 pb-100 ${props.main_class && props.main_class}`}>
        <ReuseableComponents.MainContentData
          award_and_trophies_category={props.award_and_trophies_category}
          data={props.data}
        />
      </div>
    </Fragment>
  );
};

export default MainContent;
