import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const HomeSlider = (props) => {
	return (
		<Fragment>
			<div className="slider-area">
				<ReuseableComponents.HomeSliderContent data={props.data} />
			</div>
		</Fragment>
	);
};

export default HomeSlider;
