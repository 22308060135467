import React, { Fragment } from "react";
import * as Components from "../../../Components"

const SupportAreaContent = () => {
	return (
		<Fragment>
			<div className="container">
				<div className="row">
					<Components.SupportAreaContentItem />
				</div>
			</div>
		</Fragment>
	);
};

export default SupportAreaContent;
