import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// import Image from "../../../Assets/Website/assets/img/slider/hm13-slider-1.jpg";
import * as Hooks from "../../../Hooks";

const HomeSliderItem = (props) => {
	// States...
	const [active_slide_index, setActiveSlideIndex] = Hooks.useState(0);

	// Slider Params...
	const slider_params = {
		slidesPerView: 1,
		navigation: true,
		loop: true,
		onSlideChange: (swiper) => setActiveSlideIndex(swiper.realIndex),
	};

	return (
		<Fragment>
			<div className="swiper-container">
				<Swiper
					modules={[Navigation]}
					{...slider_params}>
					{props.data &&
						props.data.length >= 1 &&
						props.data.map((item, index) => (
							<SwiperSlide key={index}>
								<div
									className="single-slider-2 slider-height-1 d-flex align-items-center slider-height-res bg-img hm-13-slider"
									style={{
										background: `url(${require(`../../../Assets/Website/assets/img/${item.image}`)})`,
										backgroundRepeat: "no-repeat",
										backgroundPosition: "50%",
									}}>
									{/* Overlay */}
									<div className="home_slider_overlay"></div>
									<div className="container">
										<div className="row">
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="slider-content-13 slider-animated-1">
													<h5
														className={`${
															active_slide_index ===
															index
																? "text_light_white animate__animated animate__bounceInDown"
																: ""
														}`}>
														{item.sub_heading}
													</h5>
													<h1
														className={`${
															active_slide_index ===
															index
																? "text_light_white animate__animated animate__bounceInLeft"
																: ""
														}`}>
														{item.heading}
													</h1>
													<div className="slider-btn btn-hover">
														<Hooks.Link
															to=""
															className={`${
																active_slide_index ===
																index
																	? "text_light_white light_white_border animate__animated animate__bounceInUp"
																	: ""
															}`}>
															SHOP NOW
														</Hooks.Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))}
				</Swiper>
			</div>
		</Fragment>
	);
};

export default HomeSliderItem;
