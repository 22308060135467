import React, { Fragment } from "react";
import * as Components from "../../../Components";

const ContactUs = () => {
	return (
		<Fragment>
			<Components.ContactUsContent />
		</Fragment>
	);
};

export default ContactUs;
