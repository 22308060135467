import React, {Fragment} from 'react';
import * as Hooks from '../../../Hooks';
import * as ReuseableComponents from '../../../ReuseableComponents';

const HeaderMobileMenu = () => {
  // States...
  const [award_icon, setAwardIcon] = Hooks.useState (true);

  // Location...
  const location = Hooks.useLocation ();

  // Params...
  const {award_and_trophy_category_name} = Hooks.useParams ();

  // On Trigger Awards Sub Menu...
  const onTriggerAwardsSubMenu = () => {
    if (award_icon === true) {
      setAwardIcon (false);
      document.querySelector ('.mobile_nav_sub_menu.awards').style.display =
        'block';
    } else {
      setAwardIcon (true);
      document.querySelector ('.mobile_nav_sub_menu.awards').style.display =
        'none';
    }
  };

  return (
    <Fragment>
      <div className="mobile-menu-area mt-2">
        <nav className="mobile_nav">
          <ul className="mobile_nav_overflow_menu">
            {/* Home */}
            <li>
              <Hooks.Link
                to=""
                className={`${location.pathname.split ('/')[1] === '' ? 'active_link' : ''}`}
              >
                Home
              </Hooks.Link>
            </li>

            {/* About Us */}
            <li>
              <Hooks.Link
                to="about_us"
                className={`${location.pathname.split ('/')[1] === 'about_us' ? 'active_link' : 'about_us'}`}
              >
                About Us
              </Hooks.Link>
            </li>

            {/* Awards & Trophies */}
            <li>
              <Hooks.Link
                to=""
                className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' ? 'active_link' : ''}`}
              >
                Awards & Trophies
              </Hooks.Link>
              <ul className="mobile_nav_sub_menu awards">
                {/* Crystal Awards */}
                <li>
                  <Hooks.Link
                    to="awards_and_trophies/crystal"
                    className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'crystal' ? 'active_link' : ''}`}
                  >
                    Crystal Awards
                  </Hooks.Link>
                </li>

                {/* Metal Awards */}
                <li>
                  <Hooks.Link
                    to="awards_and_trophies/metal"
                    className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'metal' ? 'active_link' : ''}`}
                  >
                    Metal Awards
                  </Hooks.Link>
                </li>

                {/* Acrylic Awards */}
                <li>
                  <Hooks.Link
                    to="awards_and_trophies/acrylic"
                    className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'acrylic' ? 'active_link' : ''}`}
                  >
                    Acrylic Awards
                  </Hooks.Link>
                </li>

                {/* Wooden Awards */}
                <li>
                  <Hooks.Link
                    to="awards_and_trophies/wooden"
                    className={`${location.pathname.split ('/')[1] === 'awards_and_trophies' && award_and_trophy_category_name === 'wooden' ? 'active_link' : ''}`}
                  >
                    Wooden Awards
                  </Hooks.Link>
                </li>

                {/* Marble Awards */}
                <li>
                  <Hooks.Link to="#">Marble Awards</Hooks.Link>
                </li>

                {/* Boxes */}
                <li>
                  <Hooks.Link to="#">Boxes</Hooks.Link>
                </li>
              </ul>

              {/* Expand Icon */}
              {award_icon === true
                ? <ReuseableComponents.ReactIcons.PLUS
                    className="mobile_menu_sub_icon"
                    onClick={() => onTriggerAwardsSubMenu ()}
                  />
                : <ReuseableComponents.ReactIcons.MINUS
                    className="mobile_menu_sub_icon"
                    onClick={() => onTriggerAwardsSubMenu ()}
                  />}
            </li>

            {/* Media */}
            <li>
              <Hooks.Link
                to="media"
                className={`${location.pathname.split ('/')[1] === 'media' ? 'active_link' : ''}`}
              >
                Media
              </Hooks.Link>
            </li>

            {/* Contact Us */}
            <li>
              <Hooks.Link
                to="contact_us"
                className={`${location.pathname.split ('/')[1] === 'contact_us' ? 'active_link' : ''}`}
              >
                Contact Us
              </Hooks.Link>
            </li>
          </ul>
        </nav>
      </div>
    </Fragment>
  );
};

export default HeaderMobileMenu;
