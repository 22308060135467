import React, { Fragment } from "react";

const AboutStrategyContent = () => {
	return (
		<Fragment>
			{/* Our Objectives */}
			<div className="col-lg-4 col-md-4">
				<div className="single-mission mb-30">
					<h3>Our Objectives</h3>
					{/* <p className="text_justify"> */}
						<ul>
							{/* Craftsmanship */}
							<li className="text_justify">
								<b className="mr-2">
									<i>Craftsmanship:</i>
								</b>
								Our primary objective is to maintain the highest
								level of craftsmanship in every crystal award we
								produce. We take pride in the quality and
								attention to detail that goes into each piece,
								ensuring that our customers receive products
								that are truly exceptional.
							</li>

							{/* Customer Satisfaction */}
							<li className="text_justify">
								<b className="mr-2">
									<i>Customer Satisfaction:</i>
								</b>
								We are dedicated to exceeding our customers'
								expectations by providing personalized service
								and exceptional products. Our objective is to
								understand our clients' needs and deliver
								crystal awards that not only meet but surpass
								their requirements, resulting in utmost
								satisfaction.
							</li>

							{/* Innovation */}
							<li className="text_justify">
								<b className="mr-2">
									<i>Innovation:</i>
								</b>
								We aim to stay at the forefront of design trends
								and innovative techniques in the crystal award
								industry. By embracing new technologies and
								pushing the boundaries of creativity, we seek to
								offer fresh and exciting designs that capture
								the essence of achievement in unique ways
							</li>
						</ul>
					{/* </p> */}
				</div>
			</div>

			{/* Our Mission  */}
			<div className="col-lg-4 col-md-4">
				<div className="single-mission mb-30">
					<h3>Our Mission </h3>
					<p className="text_justify">
						At Crystal Craft Trophy, our mission is to provide
						exceptional crystal awards and trophies that celebrate
						achievements and inspire a sense of pride and
						recognition. We aim to create timeless pieces of
						artistry that honor excellence across various fields,
						while exceeding the expectations of our customers with
						our craftsmanship and personalized service.
					</p>
				</div>
			</div>

			{/* Our Vission */}
			<div className="col-lg-4 col-md-4">
				<div className="single-mission mb-30">
					<h3>Our Vission</h3>
					<p className="text_justify">
						Our vision at Crystal Craft Trophy is to be the premier
						choice for individuals, organizations, and institutions
						seeking exquisite crystal awards. We strive to set new
						standards of excellence in our industry, continuously
						innovating and expanding our offerings to provide unique
						and captivating designs that leave a lasting impression.
					</p>
				</div>
			</div>
		</Fragment>
	);
};

export default AboutStrategyContent;
