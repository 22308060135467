import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const BannerArea = () => {
	return (
		<Fragment>
			<div className="banner-area pb-70">
				<ReuseableComponents.BannerAreaContent />
			</div>
		</Fragment>
	);
};

export default BannerArea;
