import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const BrandArea = () => {
	return (
		<Fragment>
			<div className="brand-logo-area pb-95">
				<ReuseableComponents.BrandAreaContent />
			</div>
		</Fragment>
	);
};

export default BrandArea;
