import React, {Fragment} from 'react';
import ImageOne
  from '../../../Assets/Website/assets/img/product/crystal_award_1.jpeg';
import * as Hooks from '../../../Hooks';

const MediaContentItem = () => {
  return (
    <Fragment>
      {[1, 2, 3].map ((_, index) => (
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="blog-wrap mb-30 scroll-zoom media_image">
            <div className="blog-img">
              <Hooks.Link to={`/media/${index + 1}`}>
                <img src={ImageOne} alt="News_Image_One" />
              </Hooks.Link>
            </div>
            <div className="blog-content-wrap">
              <div className="blog-content text-center">
                <h3>
                  <Hooks.Link to={`/media/${index + 1}`}>
                    Awards
                  </Hooks.Link>
                </h3>
                <span>
                  By Shop
                  {' '}
                  <span>Admin</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default MediaContentItem;
