import React, { Fragment } from "react";

const TextInput = (props) => {
	return (
		<Fragment>
			<input
				type={props.type}
				placeholder={props.placeholder}
				name={props.name}
			/>
		</Fragment>
	);
};

export default TextInput;
