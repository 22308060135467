import React, { Fragment } from "react";

const Map = (props) => {
	return (
		<Fragment>
			<div className={`mb-10 ${props.main_class && props.main_class}`}>
				<div id="map">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14433.034936395732!2d55.38914827748376!3d25.261880348477995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5dd76e01067b%3A0xa722bdf3f51faf22!2sAl%20Qusais%20-%20Al%20Twar%20Fourth%20-%20Dubai!5e0!3m2!1sen!2sae!4v1692597670605!5m2!1sen!2sae"
						className="w-100 h-100 rounded border-0"
						allowFullScreen=""
						loading="lazy"
						title="Contact Us Map"
						referrerPolicy="no-referrer-when-downgrade"
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default Map;
