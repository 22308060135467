import React, {Fragment} from 'react';
import * as ReuseableComponents from '../../../ReuseableComponents';

const AwardsAndTrophiesDetails = props => {
  return (
    <Fragment>
      <div className="row">
        <ReuseableComponents.AwardAndTrophyDetailContent data={props.data} />
      </div>
    </Fragment>
  );
};

export default AwardsAndTrophiesDetails;
