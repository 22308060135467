import React, { Fragment } from "react";
import ImageOne from "../../../Assets/Website/assets/img/banner/velvet_pouches.jpeg";
import ImageTwo from "../../../Assets/Website/assets/img/banner/gift_bags.jpeg";
import ImageThree from "../../../Assets/Website/assets/img/banner/wrapping_paper.jpeg";
import * as Hooks from "../../../Hooks";

const AboutBannersContentData = () => {
	return (
		<Fragment>
			<div className="col-lg-4 col-md-4">
				<div className="single-banner mb-30 about_banner_image">
					<Hooks.Link to="">
						<img
							src={ImageOne}
							alt="Velvet_Pouches_Image"
							className="rounded"
						/>
					</Hooks.Link>
					<div className="banner-content">
						<h3>Velvet Pouches</h3>
						<h4>
							Starting at <span>د.إ 0.00</span>
						</h4>
						<Hooks.Link to="">
							<i className="fa fa-long-arrow-right" />
						</Hooks.Link>
					</div>
				</div>
			</div>

			<div className="col-lg-4 col-md-4">
				<div className="single-banner mb-30 about_banner_image">
					<Hooks.Link to="">
						<img
							src={ImageTwo}
							alt="Customized_Gift_Bag_Image"
							className="rounded"
						/>
					</Hooks.Link>
					<div className="banner-content">
						<h3>Customized Gift Bags</h3>
						<h4>
							Starting at <span>د.إ 0.00</span>
						</h4>
						<Hooks.Link to="">
							<i className="fa fa-long-arrow-right" />
						</Hooks.Link>
					</div>
				</div>
			</div>

			<div className="col-lg-4 col-md-4">
				<div className="single-banner mb-30 about_banner_image">
					<Hooks.Link to="">
						<img
							src={ImageThree}
							alt="Printed_Wrapping_Paper_Image"
							className="rounded"
						/>
					</Hooks.Link>
					<div className="banner-content">
						<h3>Printed Wrapping Paper</h3>
						<h4>
							Starting at <span>د.إ 0.00</span>
						</h4>
						<Hooks.Link to="">
							<i className="fa fa-long-arrow-right" />
						</Hooks.Link>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AboutBannersContentData;
