import React, {Fragment} from 'react';
import * as Wrappers from '../../../../Wrappers';

const AwardsAndTrophiesDetails = () => {
  // Site Title...
  document.title = 'Media Details || Crystal Craft';

  return (
    <Fragment>
      {/* Bread Crumb */}
      <Wrappers.BreadCrumb actve_item_title="Media Details" />

      {/* Main Content */}
      <Wrappers.MainContent main_class="Blog-area" />
    </Fragment>
  );
};

export default AwardsAndTrophiesDetails;
