import React, {Fragment} from 'react';
import * as ReuseableComponents from "../../../ReuseableComponents"

const MediaContent = () => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <ReuseableComponents.MediaContentItem />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MediaContent;
