import React, { Fragment } from "react";

const WelcomeArea = () => {
	return (
		<Fragment>
			<div className="welcome-area pt-100 pb-95">
				<div className="container">
					<div className="welcome-content text-center">
						<h5>Who Are We</h5>
						<h1>Welcome To Crystal Craft!</h1>
						<p>
							Welcome to Crystal Craft Trophy! We are a premier
							provider of exquisite crystal awards and trophies,
							dedicated to recognizing excellence and celebrating
							achievements. With our extensive range of
							beautifully crafted crystal products, we offer a
							unique blend of elegance and artistry that is sure
							to impress.
						</p>

						<p>
							We take pride in offering a diverse selection of
							crystal awards to suit various tastes and
							preferences. From classic and timeless designs to
							contemporary and cutting-edge styles, our collection
							encompasses a wide range of options to cater to your
							specific requirements. Whether you prefer a
							traditional cup-shaped trophy or a sleek and modern
							plaque, we have the perfect solution to honor your
							deserving recipients.
						</p>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default WelcomeArea;
