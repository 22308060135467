import React, { Fragment } from "react";
import * as Components from "../../../Components";

const RelatedAwardsAndTrophies = (props) => {
	return (
		<Fragment>
			<div className="related-product-area pb-95">
				<div className="container">
					{/* Section Title One */}
					<Components.SectionTitleOne title="related awards & trophies!" />

					{/* Awards Content */}
					<Components.RelatedAwardsAndTrophiesContent
						award_and_trophies_category={
							props.award_and_trophies_category
						}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default RelatedAwardsAndTrophies;
