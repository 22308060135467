import React, {Fragment} from 'react';
import {Nav} from 'react-bootstrap';
import * as Hooks from '../../../Hooks';
import * as ReuseableComponents from '../../../ReuseableComponents';
import * as Data from '../../../Data';

const AwardsAndTrophiesTab = () => {
  // States...
  const [active_tab_item, setActiveTabItem] = Hooks.useState ('crystal');

  return (
    <Fragment>
      {/* Tab Items */}
      <div className="tab-filter-wrap mb-60">
        <div className="product-tab-list-2 nav">
          <Nav variant="tabs" className="border-0">
            {/* Crystal */}
            <Nav.Item>
              <Nav.Link
                active={active_tab_item === 'crystal'}
                onClick={() => setActiveTabItem ('crystal')}
              >
                <h4>Crystal</h4>
              </Nav.Link>
            </Nav.Item>

            {/* Metal */}
            <Nav.Item>
              <Nav.Link
                active={active_tab_item === 'metal'}
                onClick={() => setActiveTabItem ('metal')}
              >
                <h4>Metal</h4>
              </Nav.Link>
            </Nav.Item>

            {/* Acrylic */}
            <Nav.Item>
              <Nav.Link
                active={active_tab_item === 'acrylic'}
                onClick={() => setActiveTabItem ('acrylic')}
              >
                <h4>Acrylic</h4>
              </Nav.Link>
            </Nav.Item>

            {/* Wooden */}
            <Nav.Item>
              <Nav.Link
                active={active_tab_item === 'wooden'}
                onClick={() => setActiveTabItem ('wooden')}
              >
                <h4>Wooden</h4>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>

      {/* Tab Contents */}
      <div className="tab-content jump">
        <div
          className={`tab-pane ${active_tab_item ? 'active' : ''}`}
          id={active_tab_item}
        >
          <div className="row">
            <ReuseableComponents.AwardAndTrophyCard
              data={
                active_tab_item === 'crystal'
                  ? Data.CrystalAwards
                  : active_tab_item === 'metal'
                      ? Data.MetalAwards
                      : active_tab_item === 'acrylic'
                          ? Data.AcrylicAwards
                          : active_tab_item === 'wooden' && Data.WoodenAwards
              }
              award_and_trophy_category_name={active_tab_item}
              visible_items={8}
            />
          </div>

          {/* View All Button */}
          <div className="d-flex mt-4">
            <Hooks.Link
              to={`awards_and_trophies/${active_tab_item}`}
              className="view_more_button mx-auto"
            >
              {active_tab_item === 'crystal'
                ? 'View More Crystal Awards'
                : active_tab_item === 'metal'
                    ? 'View More Metal Awards'
                    : active_tab_item === 'acrylic'
                        ? 'View More Acrylic Awards'
                        : active_tab_item === 'wooden' &&
                            'View More Wooden Awards'}
            </Hooks.Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AwardsAndTrophiesTab;
