import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import ImageOne from "../../../Assets/Website/assets/img/brand-logo/barnd-logo-1.png";
import ImageTwo from "../../../Assets/Website/assets/img/brand-logo/barnd-logo-2.png";
import ImageThree from "../../../Assets/Website/assets/img/brand-logo/barnd-logo-3.png";
import ImageFour from "../../../Assets/Website/assets/img/brand-logo/barnd-logo-4.png";
import ImageFive from "../../../Assets/Website/assets/img/brand-logo/barnd-logo-5.png";

const BrandAreaContentItem = () => {
	// Owl Carousel Options...
	const owlCarouselOptions = {
		center: false,
		loop: true,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 3000,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			800: {
				items: 2,
			},
			1000: {
				items: 5,
			},
		},
	};
	return (
		<Fragment>
			<OwlCarousel className="brand-logo-active" {...owlCarouselOptions}>
				{/* Item One */}
				<div className="single-brand-logo">
					<img
						src={ImageOne}
						alt="Brand_Logo_One"
					/>
				</div>

				{/* Item Two */}
				<div className="single-brand-logo">
					<img
						src={ImageTwo}
						alt="Brand_Logo_Two"
					/>
				</div>

				{/* Item Three */}
				<div className="single-brand-logo">
					<img
						src={ImageThree}
						alt="Brand_Logo_Three"
					/>
				</div>

				{/* Item Four */}
				<div className="single-brand-logo">
					<img
						src={ImageFour}
						alt="Brand_Logo_Four"
					/>
				</div>

				{/* Item Five */}
				<div className="single-brand-logo">
					<img
						src={ImageFive}
						alt="Brand_Logo_Five"
					/>
				</div>
			</OwlCarousel>
		</Fragment>
	);
};

export default BrandAreaContentItem;
