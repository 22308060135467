import React, { Fragment } from "react";

const TextArea = (props) => {
	return (
		<Fragment>
			<textarea
				className="no_resize"
				placeholder={props.placeholder}
				name={props.name}
			/>
		</Fragment>
	);
};

export default TextArea;
