import React, { Fragment } from "react";
import * as Components from "../../index"

const ContactUsContent = () => {
	return (
		<Fragment>
			{/* Map */}
			<Components.Map main_class="contact-map" />

			<div className="custom-row-2">
				{/* Contact Info */}
				<Components.ContactInfo />

				{/* Contact Form */}
				<Components.ContactForm />
			</div>
		</Fragment>
	);
};

export default ContactUsContent;
