import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const TestimonialArea = () => {
	return (
		<Fragment>
			<div className="testimonial-area pb-95">
				<ReuseableComponents.TestimonialAreaContent />
			</div>
		</Fragment>
	);
};

export default TestimonialArea;
