import React, { Fragment } from "react";
import * as Components from "../../../Components";

const BannerAreaContent = () => {
	return (
		<Fragment>
			<div className="container">
				<div className="row">
					<Components.BannerAreaContentItem />
				</div>
			</div>
		</Fragment>
	);
};

export default BannerAreaContent;
