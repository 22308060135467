import React, { Fragment } from "react";
import LogoImage from "../../../Assets/Website/assets/img/logo/logo.jpg";
import * as Components from "../../../Components";

const Header = () => {
	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					{/* Header Logo */}
					<Components.HeaderLogo
						image={LogoImage}
					/>

					{/* Header Menu */}
					<Components.HeaderMenu />

					{/* Header Icons */}
					<Components.HeaderIcons />
				</div>

				{/* Mobile Menu */}
				<Components.HeaderMobileMenu />
			</div>
		</Fragment>
	);
};

export default Header;
