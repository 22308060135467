import React, { Fragment } from "react";

const SelectField = (props) => {
	return (
		<Fragment>
			<select name={props.name} className="select_field">
				<option>{props.default_option_text}</option>

				{props.options && props.options.length >= 1 ? (
					props.options.map((item, index) => (
						<option
							key={index}
							value={item.value}>
							{item.label}
						</option>
					))
				) : (
					<option value="Inquiry Types Not Found">
						Inquiry Types Not Found
					</option>
				)}
			</select>
		</Fragment>
	);
};

export default SelectField;
