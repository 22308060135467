import React, { Fragment } from "react";

const SectionTitleOne = (props) => {
	return (
		<Fragment>
			<div className="section-title text-center mb-55">
				{props.title && <h2 className="text-uppercase">{props.title}</h2>}
			</div>
		</Fragment>
	);
};

export default SectionTitleOne;
