import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import * as ReuseableComponents from "../../../ReuseableComponents";

const AwardAndTrophyModal = (props) => {
	return (
		<Fragment>
			<Modal
				className="fade zoom product_modal "
				show={props.open_modal}
				onHide={props.close_modal}
				backdrop="static"
				centered>
				{/* Header */}
				<Modal.Header>
					<h3 className="modal_header_title">{props.header_title}</h3>

					<ReuseableComponents.ReactIcons.OUTLINE_CLOSE
						className="modal_header_close"
						onClick={props.close_modal}
					/>
				</Modal.Header>

				{/* Body */}
				<Modal.Body>
					<div className="row">
						{/* Image */}
						<div className="col-md-5 col-sm-12 col-xs-12">
							<div className="quickview-big-img">
								<img
									src={require(`../../../Assets/Website/assets/img/${props.award_and_trophy_data.thumbnail_image}`)}
									alt={`Product_${props.award_and_trophy_data.name}_Image`}
									className="img-thumbnail"
								/>
							</div>
						</div>

						{/* Detail */}
						<div className="col-md-7 col-sm-12 col-xs-12">
							<div className="product-details-content quickview-content">
								{/* Name */}
								<h2>{props.award_and_trophy_data.name}</h2>

								{/* Price */}
								<div className="product-details-price">
									{props.award_and_trophy_data.discount >
									0 ? (
										<Fragment>
											{/* Discounted Price */}
											{props.award_and_trophy_data
												.discount > 0 && (
												<span>
													{props.award_and_trophy_data
														.currency_symbol +
														" " +
														(
															props
																.award_and_trophy_data
																.price -
															(props
																.award_and_trophy_data
																.discount /
																100) *
																props
																	.award_and_trophy_data
																	.price
														).toFixed(2)}
												</span>
											)}

											{/* Original Price */}
											{props.award_and_trophy_data
												.discount > 0 && (
												<span className="old">
													{props.award_and_trophy_data
														.currency_symbol +
														" " +
														props.award_and_trophy_data.price.toFixed(
															2,
														)}
												</span>
											)}
										</Fragment>
									) : (
										<Fragment>
											{/* Original Price */}
											<span>
												{props.award_and_trophy_data
													.currency_symbol +
													" " +
													props.award_and_trophy_data.price.toFixed(
														2,
													)}
											</span>
										</Fragment>
									)}
								</div>

								{/* Reviews Ratting */}
								<ReuseableComponents.ReviewsRatting
									ratting={
										props.award_and_trophy_data.reviews
									}
								/>

								{/* Description */}
								<p className="text_justify">
									{
										props.award_and_trophy_data
											.short_description
									}
								</p>

								{/* Offers */}
								{props.award_and_trophy_data.offers &&
									props.award_and_trophy_data.offers.length >=
										1 &&
									props.award_and_trophy_data.offers.map(
										(offer_item, offer_index) => (
											<Fragment key={offer_index}>
												{/* Offer Items */}
												<div className="pro-details-list">
													<p className="text-muted">
														<i>
															{offer_item.heading}
														</i>
													</p>
													<ul className="pl-3">
														{offer_item.items &&
															offer_item.items
																.length >= 1 &&
															offer_item.items.map(
																(
																	offer_item_data,
																	offer_item_index,
																) => (
																	<Fragment
																		key={
																			offer_item_index
																		}>
																		<li className="text_justify">
																			{offer_item_data.title && (
																				<b className="mr-2 text-dark">
																					<i>
																						{
																							offer_item_data.title
																						}

																						:
																					</i>
																				</b>
																			)}
																			{
																				offer_item_data.text
																			}
																		</li>
																	</Fragment>
																),
															)}
													</ul>
												</div>
											</Fragment>
										),
									)}
							</div>
						</div>
					</div>
				</Modal.Body>

				{/* Footer */}
				<Modal.Footer className="justify-content-center border-0 pb-2 pt-0"></Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default AwardAndTrophyModal;
