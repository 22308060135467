import React, {Fragment} from 'react';
import * as Wrappers from '../../../Wrappers';
import * as Data from '../../../Data';

const Home = () => {
  // Site Title...
  document.title = 'Home || Crystal Craft';

  return (
    <Fragment>
      {/* Home Slider */}
      <Wrappers.HomeSlider data={Data.HomeSlider} />

      {/* Awards And Trophies Tab */}
      <Wrappers.AwardsAndTrophiesTab />

      {/* Support Area */}
      <Wrappers.SupportArea />

      {/* Testimonial Area */}
      <Wrappers.TestimonialArea />

      {/* Banner Area */}
      <Wrappers.BannerArea />

      {/* Brand Area */}
      <Wrappers.BrandArea />

      {/* Media */}
      <Wrappers.Media />
    </Fragment>
  );
};

export default Home;
