import React, { Fragment } from "react";
import * as ReuseableComponents from "../../../ReuseableComponents"

const AboutStrategy = () => {
	return (
		<Fragment>
			<div className="about-mission-area pb-70">
				<div className="container">
					<div className="row">
						<ReuseableComponents.AboutStrategyContent />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AboutStrategy;
