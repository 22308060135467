import React, { Fragment } from "react";
import ImageOne from "../../../Assets/Website/assets/img/icon-img/support-1.png";
import ImageTwo from "../../../Assets/Website/assets/img/icon-img/support-2.png";
import ImageThree from "../../../Assets/Website/assets/img/icon-img/support-3.png";

const SupportAreaContentItem = () => {
	return (
		<Fragment>
			{/* Free Shipping */}
			<div className="col-lg-4 col-md-4 col-sm-6">
				<div className="support-wrap-2 support-padding-2 support-shape-3 mb-30 text-center">
					<div className="support-content-2">
						<img
							className="animated"
							src={ImageOne}
							alt="Support_Image_One"
						/>
						<h5>Free Shipping</h5>
						<p>
							Lorem ipsum dolor sit amet consectetu adipisicing
							elit sed
						</p>
					</div>
				</div>
			</div>

			{/* Support 24/7 */}
			<div className="col-lg-4 col-md-4 col-sm-6">
				<div className="support-wrap-2 support-padding-2 support-shape-3 mb-30 text-center">
					<div className="support-content-2">
						<img
							className="animated"
							src={ImageTwo}
							alt="Support_Image_Two"
						/>
						<h5>Support 24/7</h5>
						<p>
							Lorem ipsum dolor sit amet consectetu adipisicing
							elit sed
						</p>
					</div>
				</div>
			</div>

			{/* Money Return */}
			<div className="col-lg-4 col-md-4 col-sm-6">
				<div className="support-wrap-2 support-padding-2 support-shape-3 mb-30 text-center">
					<div className="support-content-2">
						<img
							className="animated"
							src={ImageThree}
							alt="Support_Image_Three"
						/>
						<h5>Money Return</h5>
						<p>
							Lorem ipsum dolor sit amet consectetu adipisicing
							elit sed
						</p>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default SupportAreaContentItem;
