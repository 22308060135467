import React, { Fragment } from "react";
import LogoImage from "../../../Assets/Website/assets/img/logo/logo.jpg";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Hooks from "../../../Hooks";

const FooterTop = () => {
	return (
		<Fragment>
			{/* First Side */}
			<div className="col-lg-3 col-md-4 col-sm-4">
				<div className="copyright mb-30">
					{/* Logo */}
					<ReuseableComponents.FooterLogo image={LogoImage} />

					{/* Address */}
					<p className="text_justify">Al , Qusais, Dubai</p>
				</div>
			</div>

			{/* Second Side */}
			<div className="col-lg-3 col-md-4 col-sm-4">
				<div className="footer-widget mb-30 ml-30">
					<div className="footer-title">
						<h3>ABOUT US</h3>
					</div>
					<div className="footer-list">
						<ul>
							<li>
								<Hooks.Link to="">About us</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Store location</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Contact</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Orders tracking</Hooks.Link>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/* Third Side */}
			<div className="col-lg-3 col-md-4 col-sm-4">
				<div className="footer-widget mb-30 ml-50">
					<div className="footer-title">
						<h3>USEFUL LINKS</h3>
					</div>
					<div className="footer-list">
						<ul>
							<li>
								<Hooks.Link to="">Returns</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Support Policy</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Size guide</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">FAQs</Hooks.Link>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/* Forth Side */}
			<div className="col-lg-3 col-md-6 col-sm-6">
				<div className="footer-widget mb-30 ml-75">
					<div className="footer-title">
						<h3>FOLLOW US</h3>
					</div>
					<div className="footer-list">
						<ul>
							<li>
								<Hooks.Link to="">Facebook</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Twitter</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Instagram</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="">Youtube</Hooks.Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default FooterTop;
