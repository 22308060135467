import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import Image from "../../../Assets/Website/assets/img/testimonial/testi-1.png";

const TestimonialAreaContentItem = () => {
	// Owl Carousel Options...
	const owlCarouselOptions = {
		center: false,
		loop: true,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 3000,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 1,
			},
			800: {
				items: 1,
			},
			1000: {
				items: 1,
			},
		},
	};
	return (
		<Fragment>
			<OwlCarousel
				className="testimonial-active nav-style-1 nav-testi-style"
				{...owlCarouselOptions}>
				<div className="single-testimonial text-center">
					<img
						src={Image}
						alt="Testimonial_Image"
					/>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing
						elit, sed do eiusmod tempor incididunt ut labore et
						dolore magna aliqua. Ut enim ad minim veniam, quis
						nostrud exercitation ullamco laboris nisi ut aliquip ex
						ea commodo consequat Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu
						fugiat nulla pariatur.
					</p>
					<div className="client-info">
						<i className="fa fa-map-signs" />
						<h5>Grace Alvarado</h5>
						<span>Customer</span>
					</div>
				</div>
			</OwlCarousel>
		</Fragment>
	);
};

export default TestimonialAreaContentItem;
