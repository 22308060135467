import React, {Fragment} from 'react';
import * as Wrappers from '../../../../Wrappers';
import * as Hooks from '../../../../Hooks';
import * as Data from '../../../../Data';

const AwardsAndTrophiesDetails = () => {
  // Site Title...
  document.title = 'Awards And Trophies Details || Crystal Craft';

  // Params...
  const {
    award_and_trophy_category_name,
    award_and_trophy_category_id,
  } = Hooks.useParams ();

  // Get Data By ID...
  let data;

  if (award_and_trophy_category_name === 'crystal') {
    data = Data.CrystalAwards.find (item => {
      return item.id === parseInt (award_and_trophy_category_id);
    });
  } else if (award_and_trophy_category_name === 'metal') {
    data = Data.MetalAwards.find (item => {
      return item.id === parseInt (award_and_trophy_category_id);
    });
  } else if (award_and_trophy_category_name === 'acrylic') {
    data = Data.AcrylicAwards.find (item => {
      return item.id === parseInt (award_and_trophy_category_id);
    });
  } else if (award_and_trophy_category_name === 'wooden') {
    data = Data.WoodenAwards.find (item => {
      return item.id === parseInt (award_and_trophy_category_id);
    });
  } else {
    return false;
  }

  console.log (data);

  return (
    <Fragment>
      {/* Bread Crumb */}
      <Wrappers.BreadCrumb
        actve_item_title={award_and_trophy_category_name + ' award details'}
      />

      {/* Main Content */}
      <Wrappers.MainContent
        main_class="shop-area"
        award_and_trophies_category={award_and_trophy_category_name}
        data={data}
      />

      {/* Related Awards And Trophies */}
      <Wrappers.RelatedAwardsAndTrophies
        award_and_trophies_category={award_and_trophy_category_name}
      />
    </Fragment>
  );
};

export default AwardsAndTrophiesDetails;
